<template>
  <div class="treasury-setting">

    <div class="mb-base">
      <div class="flex items-center mb-4">
        <input id="treasuryNegative" type="checkbox" v-model="negativeValue" />
        <label for="treasuryNegative" class="ml-2">{{ $t('setting.Treasury.labels.treasuryCanNegative') }}</label>
      </div>
    </div>


    <vs-button v-show="false"
               id="editTreasurySettingBTN"
               @click="sendData"/>
  </div>
</template>

<script>
import axios from "axios";
import {editAppSetting, getAppSetting} from "../../../../http/requests/setting";

export default {
  name: 'TreasurySettings',
  data () {
    return {
      negativeValue: false
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.auth.user
    }
  },
  created () {
    this.$store.dispatch('updateContentNavbarStyle', 'sticky')

    this.getTreasurySetting()
  },

  methods: {
    getTreasurySetting() {
      getAppSetting().then(response => {
        const app = response.data.data
        if (app.treasury_with_negative_balance === '0') {
          this.negativeValue = false
        } else {
          this.negativeValue = true
        }
      })
    },
    sendData() {
      const payload = {
        treasury_with_negative_balance: this.negativeValue
      }
      editAppSetting(payload).then(response => {
        this.$vs.notify({
          time: 2400,
          title: this.$t('alert.message.title'),
          text: this.$t('setting.notifications.editTreasury.success'),
          iconPack: 'feather',
          icon: 'icon-check',
          color: 'success'
        })
        this.$emit('edit')
      }).catch(error => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          switch (error.response.status) {
            case 406:
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('setting.notifications.editTreasury.negativeBalance'),
                color: 'danger',
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 5000
              })
              break

            default:
              this.$vs.notify({
                time: 2400,
                title: this.$t('alert.error.title'),
                text: error.response.data.message || error.response.data.error,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
              break
          }
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .treasury-setting {
    padding-top: 10px;

    input[type=checkbox] {
      height: 18px;
      width: 18px;
    }
  }
</style>
